/*******************************************************************************
 * Styles for the base elements of the theme.
 ******************************************************************************/

// Typography

a, table.table a {
  color: $theme-color;
  &:hover {
    color: $theme-color;
    text-decoration: underline;
  }
  &:hover:after {
    width: 100%;
  }
}


// Math

.equation {
  margin-bottom: 1rem;
  text-align: center;
}

// Caption

.caption {
  font-size: 0.875rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  text-align: center;
}





// Navbar customization

.navbar {
  box-shadow: none;
  border-bottom: 1px solid $grey-color-light;
  opacity: 0.95;
}
.navbar.navbar-light {
  // Remove link decoration
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .navbar-nav .nav-item .nav-link {
    &:hover {
      color: $theme-color;
    }
  }
  .navbar-nav .nav-item.active>.nav-link {
      background-color: inherit;
      color: $theme-color;
      font-weight: bolder;
      &:hover {
        color: $theme-color;
      }
  }
  .contact-icon {
    font-size: 2rem;
    a {
      color: $grey-color-dark;
      &:hover {
        color: $theme-color;
      }
    }
  }
}

.navbar-toggler {
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    background-color: $grey-color-dark;
    border-radius: 1px;
    margin-bottom: 4px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
}

.navbar-toggler.collapsed {
  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}


// News

.news table td {
  font-size: 1rem;
}


// Social (under photo)

.name {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  text-align: center;
  font-size: 1.25em;
}

.affiliation {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  text-align: center;
  font-size: 0.95em;
}

.social {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  text-align: center;
  font-size: 0.95em;
}


// Distill

.distill {
  a:hover {
    border-bottom-color: $theme-color;
    text-decoration: none;
  }
}



// Publications

.publications {
  margin-bottom: 1rem;

  h2.year {
    color: $grey-color-dark;
    border-top: 1px solid $grey-color-light;
    padding-top: 3rem;


    margin-bottom: -3rem;
    text-align: right;
    font-size: 0.85em;
    font-style: italic;
  }

  h3{
    font-size: 1.6em;
      padding-top: 0.1em;
      text-align: center;
  }

  ol.bibliography {
    border-bottom: 1px solid $grey-color-light;
    list-style: none;
    padding: 0;
    margin-top: 1;

    li {
      margin-bottom: 1rem;
      span {
        display: block;
      }
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed $theme-color;
          &:hover {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
      }
      em.star {
        border-bottom: none;
        color: $theme-color;
        font-style: normal;
      }
      a.abstract, a.bibtex {
        cursor: pointer;
      }
      .hidden {
        font-size: 0.875rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 1em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .hidden.open {
        max-height: 100em;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;
      }
      span.abstract.hidden {
        border: dashed 1px white;
      }
      span.abstract.hidden.open {
        border-color: $grey-color;
      }
    }
  }
}

