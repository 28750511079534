@charset "utf-8";

@import
  "variables",
  "layout",
  "base"
;


.post a.btn { color: #424242; border: 1px solid #424242; padding-left: 0.25rem; padding-right: 0.25rem; padding-top: 0.05rem; padding-bottom: 0.05rem; font-size: 0.65em; margin-left: 0.1rem; }

.post a.btn:hover { color: #B509AC; border-color: #B509AC; }
.publications ol.bibliography li { margin-bottom: 0 !important; }

.publications ol.bibliography li .links a.btn { color: #424242; border: 1px solid #424242; padding-left: 0.25rem; padding-right: 0.25rem; padding-top: 0.05rem; padding-bottom: 0.05rem; font-size: 0.65em; margin-left: 0.1rem; }
.publications ol.bibliography li .links a.btn:hover { color: #B509AC; border-color: #B509AC; }

.publications ol.bibliography { border-bottom: 0px solid #e8e8e8; list-style: none; padding: 0; margin-top: 1; }
.publications h2.year { color: lightSlateGray; border-top: 0px solid #e8e8e8; margin-bottom: -2.75rem; text-align: right; font-size: 0.85em; font-style: italic; padding-top: 2.5rem; }
.publications h3 { font-size: 1.5em; padding-top: 0.75em; text-align: center; padding-bottom: 0.0em; }



.experience { padding: 0; }

.experience h2 { font-size: 1.5em; padding-top: 0.05em; text-align: center; padding-bottom: 0.5em; }

.experience img.uni-logo { display: block; margin: 0 auto;
max-height: 60px; max-width: 90%; width: auto; padding-top: 0.05em; text-align: center; padding-bottom: 1.0em; }


.experience h4 { font-size: 1.00em; font-style: italic; text-align: center; }
.experience h4.TA { padding-bottom: 2.00rem; }

.experience a.TA1_classes { font-style: italic; border-bottom: 1px dashed lightSlateGray; }
.experience a.TA1_classes:hover { border-bottom-style: solid; text-decoration: none; }

.experience a.TA2_classes { font-style: italic; border-bottom: 1px dashed lightSlateGray; }
.experience a.TA2_classes:hover { border-bottom-style: solid; text-decoration: none; }

.experience ol.bibliography { list-style: none; padding-left: 0; }
.experience ol.bibliography li { padding-bottom: 0.75rem; }
.experience ol.bibliography li span { display: block; }

.experience ol.bibliography li .year-right { padding-top: 3.0px; float: right; color: lightSlateGray; text-align: right; font-size: 0.85em; font-style: italic; width: 27.5%}



.experience ol.bibliography li .title-left { float: left; width: 72.5%}
.experience ol.bibliography li div.title-left .dept { color: lightSlateGray; font-size: 0.85em; font-style: italic }


.experience ol.bibliography li div.title-left .dept a { color: lightSlateGray; font-style: italic; border-bottom: 1px dashed lightSlateGray; }
.experience ol.bibliography li div.title-left .dept a:hover { border-bottom-style: solid; text-decoration: none; }

.experience ol.bibliography li .syllabus-etc a.btn { color: #424242; border: 1px solid #424242; padding-left: 0.25rem; padding-right: 0.25rem; padding-top: 0.05rem; padding-bottom: 0.05rem; font-size: 0.65em; margin-left: 0.1rem; }

.experience ol.bibliography li .syllabus-etc a.btn:hover { color: #B509AC; border-color: #B509AC; }


.experience ol.bibliography li div.title-left .lecturer { font-size: 0.85em; }
.experience ol.bibliography li div.title-left .lecturer a { border-bottom: 1px dashed #B509AC; }
.experience ol.bibliography li div.title-left .lecturer a:hover { border-bottom-style: solid; text-decoration: none; }

.experience ol.bibliography li div.title-left .lecturer > em { border-bottom: 1px solid; font-style: normal; }


.experience ol.bibliography li div.title-left .syllabus-etc { }


.experience .TA1_classes.hidden { display: none!important; max-height: 0px; -webkit-transition: 0.15s ease; -moz-transition: 0.15s ease; -ms-transition: 0.15s ease; -o-transition: 0.15s ease; transition: all 0.15s ease; }

.experience .TA1_classes.hidden.open { display: block!important; max-height: 100em; -webkit-transition: 0.15s ease; -moz-transition: 0.15s ease; -ms-transition: 0.15s ease; -o-transition: 0.15s ease; transition: all 0.15s ease; }

.experience .TA2_classes.hidden { display: none!important; max-height: 0px; -webkit-transition: 0.15s ease; -moz-transition: 0.15s ease; -ms-transition: 0.15s ease; -o-transition: 0.15s ease; transition: all 0.15s ease; }

.experience .TA2_classes.hidden.open { display: block!important; max-height: 100em; -webkit-transition: 0.15s ease; -moz-transition: 0.15s ease; -ms-transition: 0.15s ease; -o-transition: 0.15s ease; transition: all 0.15s ease; }


.publications ol.bibliography { margin-bottom: 0 !important; }
.publications ol.bibliography li { margin-bottom: 0 !important; }


.experience ol.fa-ul { font-size: 0.85em; margin-top: -0.75rem !important;}
.experience ol.fa-ul li { padding-bottom: 0.25rem; }
