/******************************************************************************
 * Content
 ******************************************************************************/

 body {
   color: $text-color;
 }

 body.fixed-top-nav {
   // Add some padding for the nav-bar.
   padding-top: 56px;
 }

 .footer {
    margin-top: 1.25rem;
    color: white;
    text-align: center;
  }

  .footer p {
    font-size: 0.75em;
    color: black;
    margin-top: 0.55rem;
    padding-bottom: 0.25rem;
  }

  .footer div.social {
    padding-top: 0.00rem;
    margin-top: 0.00rem;
  }

  .footer div.social a.social-btn {
    color: #424242;
    padding: 10px;
    font-size: 30px;
    width: 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
  }

  .footer div.social a.social-btn:hover {
    opacity: 0.5;
  }

.container {
  max-width: $max-content-width;
}

// Profile
.profile {
text-align: center;
  img {
    align: center;
    width: 100%;
    max-width: 300px;
  }
  .afterpic_content{
      margin-top: 1.50rem;
      text-align: left;
  }
}


// TODO: redefine content layout.


/******************************************************************************
 * Publications
 ******************************************************************************/

// TODO: redefine publications layout.


/*****************************************************************************
* Projects
*****************************************************************************/

// TODO: redefine projects layout.
